<template>
  <div class="home">
    <div class="space-between">
      <div>
        <el-input
          v-model="searchData.brandingName"
          placeholder="搜索活动名称"
          class="search-input"
          clearable
          @change="init"
        />
        <el-select
          v-model="searchData.status"
          class="mgn-r20"
          clearable
          placeholder="活动状态"
          @change="init"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="space-between">
        <el-button
          type="primary"
          class="qxs-btn-md"
          icon="el-icon-plus"
          @click="addActivity"
        >
          新建活动
        </el-button>
      </div>
    </div>
    <div
      ref="table"
      class="prod-panel"
    >
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="activityList"
        border
        style="width: 100%;"
      >
        <el-table-column
          prop="title"
          label="活动名称"
        />
        <el-table-column
          label="活动状态"
          prop="status"
        >
          <template slot-scope="scoped">
            {{ scoped.row.status === 0? '未发布' : '已发布' }}
          </template>
        </el-table-column>
        <el-table-column
          label="转发量"
          prop="forwardRecordCount"
        />
        <el-table-column
          label="打开用户/推送用户"
          prop="hospital"
        >
          <template slot-scope="scoped">
            {{ scoped.row.openCount + ' / ' + scoped.row.pushCount }}
          </template>
        </el-table-column>
        <el-table-column
          label="活动主办方"
          prop="organizer"
        />
        <el-table-column
          label="更新时间"
          prop="updateDate"
        >
          <template slot-scope="scoped">
            {{ $dayjs(scoped.row.updateDate).format('YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
          label="链接"
          prop="brandingUrl"
        />
        <el-table-column
          prop="zip"
          label="操作"
        >
          <template slot-scope="scoped">
            <el-button
              type="text"
              @click.native.prevent="jumpDetail(scoped.row)"
            >
              查看
            </el-button>
            <el-button
              v-if="scoped.row.status === 0"
              type="text"
              @click.native.prevent="jumpCreatActivity(scoped.row)"
            >
              编辑
            </el-button>
            <el-button
              v-if="scoped.row.status === 0"
              type="text"
              @click.native.prevent="onDel(scoped.row)"
            >
              删除
            </el-button>
            <el-button
              v-if="scoped.row.status === 1"
              type="text"
              @click.native.prevent="onCancelPush(scoped.row)"
            >
              取消发布
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="searchData.pageNo"
        :page-size="searchData.pageSize"
        layout="total,prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ActivityManagement',
  components: {},
  data() {
    return {
      loading: true,
      activityList: [],
      name: '活动列表',
      searchData: {
        brandingName: '',
        pageNo: 1,
        pageSize: 10,
        status: ''
      },
      totalCount: 0,
      options: [
        {
          value: '0',
          label: '未发布'
        },
        {
          value: '1',
          label: '已发布'
        }
      ]
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$axios.get(this.$API.getActivityList, {params: this.searchData }).then(res => {
        this.loading = false
        this.activityList = res?.data || []
        this.totalCount = res?.totalCount || 0
      }, rea => {
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    jumpDetail(row) {
      this.$router.push({ name: 'ActivityDetail', params: { id: row.id } })
    },
    handleCurrentChange(val) {
      this.searchData.pageNo = val
      this.init()
    },
    addActivity() {
      this.$router.push({ name: 'CreateActivityManagement' })
    },
    jumpCreatActivity(row) {
      this.$router.push({ name: 'CreateActivityManagement', params: { brandingId: row.id }  })
    },
    onDel(row) {
      this.$confirm('请确认是否删除该活动', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$API.delActivity, null, { params: { id: row.id } }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功'
          })
          this.init()
        }, rea => {
          this.$message.error(rea.message)
        })
      })
    },
    onCancelPush(row) {
      this.$confirm('请确认是否取消发布该活动', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$API.changeActivityStatus, null, { params: {status: 0, id: row.id }}).then(() => {
          this.$message({
            type: 'success',
            message: '取消发布成功'
          })
          this.init()
        }, rea => {
          this.$message.error(rea.message)
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.el-pagination {
  padding-top: 26px;
  text-align: center;
}
.space-between {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.search-input {
  width: 400px;
  margin: 0 10px;
}
</style>
